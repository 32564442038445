<template lang="pug">
.main-wrapper.empresa-visualizar
        MostrarPaciente(
            :waitingPaciente='waitingPaciente'
            :model='modelUser'
            :isTitular = "selectedPai"
            :empresaId='this.model.id'
            @close='dialogUser.visible=false'
            :dialogPaciente='dialogUser.visible'
        )

        DialogAnexos(
            v-if='dialogAnexos'
            :display='dialogAnexos'
            :anexos='dialogAnexos_data'
            @close='dialogAnexos = false'
        )

        Dialog.dialogApagar(header='Solicitar inativação do usuário' :visible.sync='dialogApagar' :modal='true')
            .p-grid.p-fluid.p-align-top.p-justify-center
                .p-col-12
                    p Deseja solicitar a inativação do usuario <b>{{ dialogApagar_data?.nm_pessoa_fisica }}</b>?
                .p-col-12(style="align-self: flex-end;")
                    .p-inputgroup
                        .p-input-icon-left.p-input-icon-right
                            InputText(
                                type="text"
                                placeholder="Anexar"
                                v-model="model.nm_comprovante_dialog"
                                @click="$refs.refComprovanteDialog.click()"
                                readonly)
                            i.jam.jam-attachment(@click="$refs.refComprovanteDialog.click()" v-tooltip.top="'Anexar'")
                        Button.p-button-success(
                            icon="jam jam-plus"
                            v-tooltip.top="'Anexar'"
                            @click="$refs.refComprovanteDialog.click()")

                    input(
                        v-show='false'
                        type="file"
                        ref="refComprovanteDialog"
                        @change="nomeComprovanteDialog()"
                        accept=".")
                .p-col-12(:class="{ 'form-group--error': remocao && $v.model.ie_motivo_dialog.$error }")
                    label.form-label Motivo de inativação:
                    Dropdown(v-model='$v.model.ie_motivo_dialog.$model' :options='options.ie_motivo'
                        optionLabel='text' optionValue='value' placeholder='Selecione')
                    .feedback--errors(v-if='remocao && $v.model.ie_motivo_dialog.$error')
                        .form-message--error(v-if="!$v.model.ie_motivo_dialog.required") <b>Motivo</b> é obrigatório.
                .p-col-12(:class="{ 'form-group--error': remocao && $v.model.ie_motivo_dialog.$error }")
                    label.form-label Observação:
                    Textarea(v-model='$v.model.ds_motivo_dialog.$model' rows='3' style='width:100% !important')
                    .feedback--errors(v-if='remocao && $v.model.ds_motivo_dialog.$error')
                        .form-message--error(v-if="!$v.model.ds_motivo_dialog.required") <b>Observação</b> é obrigatório.
                .p-col-12.ta-center
                    ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                    Button.p-button-warning(v-else icon='jam jam-minus-circle' label='Solicitar inativação' @click='remove()')

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-12.ta-right
                        h1.text-header.text-secondary MedClub / Empresa / <b>Gestão de Contatos</b>

            div
                ProgressBar(v-if='waiting' mode="indeterminate")
                div(v-else)
                    Panel(header="Dados Empresa")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-3
                                label.form-label Razão social:
                                InputText(type='text' v-model='model.nm_razao_social' readonly)

                            .p-col-12.p-md-3
                                label.form-label CNPJ:
                                InputText(mask='99.999.999/9999-99' v-model='model.nr_cnpj_f' readonly)

                            .p-col-12.p-md-3
                                label.form-label Nome fantasia:
                                InputText(type='text' v-model='model.nm_fantasia' readonly)

                            .p-col-12.p-md-3
                                label.form-label Responsável:
                                InputText(type='text' v-model='model.nm_responsavel' readonly)

                            .p-col-12.p-md-3
                                label.form-label Bairro:
                                InputText(type='text' v-model='model.nm_bairro' readonly)

                            .p-col-12.p-md-3
                                label.form-label Rua:
                                InputText(type='text' v-model='model.nm_rua' readonly)

                            .p-col-12.p-md-6
                                label.form-label Complemento:
                                InputText(type='text' v-model='model.ds_complemento' readonly)

                            .p-col-12.p-md-3
                                label.form-label CEP:
                                InputText(mask='99999-999' v-model='model.nr_cep_f' readonly)

                            .p-col-12.p-md-3
                                label.form-label Número da Empresa:
                                InputText(type='text' v-model='model.nr_empresa' readonly)

                            .p-col-12.p-md-3
                                label.form-label Telefone:
                                InputText(:mask="'(99) 99999999?9'" v-model='model.nr_telefone_f' readonly)

                            .p-col-12.p-md-3
                                label.form-label Email:
                                InputText(type='text' v-model='model.ds_email' readonly)

                Panel.datatable.mt-3(header='Lista de correntistas')
                    .p-grid.p-fluid.p-align-end
                        .p-col-4
                            label.form-label Nome:
                            InputText(type='text' v-model='filters.nm_pessoa_fisica' @keyup.enter="applyFilters()")

                        .p-col-2
                            label.form-label CPF:
                            InputMask(
                                :mask="'999.999.999-99'"
                                v-model="filters.nr_cpf"
                                @keydown.enter="applyFilters()"
                                :autoClear="false")

                        .p-col-2
                            label.form-label Situação:
                            Dropdown(
                                placeholder='Selecione'
                                :options='options.situacoes'
                                optionLabel='text'
                                optionValue='value'
                                @change='applyFilters()'
                                v-model='filters.ie_situacao'
                                filter
                            )

                        .p-col-2
                            label.form-label Faixa:
                            Dropdown(
                                placeholder='Selecione'
                                :options='options.faixas'
                                optionLabel='text'
                                optionValue='value'
                                @change='applyFilters()'
                                v-model='filters.cd_faixas'
                                filter
                            )

                        .p-col-2
                            Button(
                                label='Filtrar'
                                icon='jam jam-search'
                                :disabled='waitingAssociados'
                                @click="applyFilters()")
                    
                        .p-col-12.ta-right(v-if='!isAdd')
                            Button(
                                label='Adicionar'
                                icon='jam jam-plus'
                                style='max-width:15.8%'
                                @click="isAdd = true")

                        .p-col-7.buscar-paciente(:class="{ naoVer: !isAdd}")
                            BuscarPaciente(ref='buscarPaciente' label='Cliente' :cancelarPopup='true',
                                @change = 'clienteAlterado = true' 
                                @limpa = 'clienteAlterado = false; isAdd = false;' 
                                @busca = 'clienteAlterado = true'
                            )

                        .p-col-2(v-if="isAdd" :style="{'align-self': 'flex-end', 'padding-bottom': clienteAlterado? '1.3%' : '3%'}")
                            .p-inputgroup
                                .p-input-icon-left.p-input-icon-right
                                    InputText(
                                        type="text"
                                        placeholder="Anexar"
                                        v-model="model.nm_comprovante"
                                        @click="$refs.refComprovante.click()"
                                        readonly)
                                    i.jam.jam-attachment(@click="$refs.refComprovante.click()" v-tooltip.top="'Anexar'")
                                Button.p-button-success(
                                    icon="jam jam-plus"
                                    v-tooltip.top="'Anexar'"
                                    @click="$refs.refComprovante.click()")

                            input(
                                v-show='false'
                                type="file"
                                ref="refComprovante"
                                @change="nomeComprovante()"
                                accept=".")
                            
                        .p-col-3(v-if="isAdd")
                            div.ta-center(:style="{'padding-bottom': clienteAlterado? '3%' : '10.4%'}")
                                ProgressSpinner(v-if='waitingAddC' :style={"width":"45px", "height": "45px"} strokeWidth='4')
                                Button(
                                    v-else
                                    label='Solicitar vínculo de correntista'
                                    icon='jam jam-plus'
                                    :disabled='!clienteAlterado'
                                    @click="handleSubmit()")

                        .p-col-12.ta-right(v-if="isAdd")
                            Button(
                                label='Cancelar'
                                icon='jam jam-close'
                                style='max-width:15.5%'
                                @click="isAdd = false; clienteAlterado = false; model.aq_anexo = null; model.nm_comprovante = '';")

                    ProgressBar(v-if='waitingAssociados' mode="indeterminate")

                    div(v-else-if="!list.length")
                        p.ta-center Nenhum item encontrado

                    div(v-else)

                        DataTable(:value='totalizadores')
                            Column(
                                v-for='t in tableData'
                                :key="t.header"
                                :headerStyle='t.hStyle'
                                :header='t.header'
                                :field='t.field'
                                :bodyStyle='t.bStyle'
                                :sortable='t.sort'
                            )
                                template(#body='{data}')
                                    b(v-html='$root.formatPrice(data[t.field])')

                        Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")
                        DataTable.datatable(:value="list")
                            Column(headerStyle="width: 16%;" field="nm_pessoa_fisica" header="Correntista")
                                template(#body='{data}')
                                    b {{ data.nm_pessoa_fisica }}
                                    br
                                    em 
                                        b CPF: 
                                        span {{ data.nr_cpf_f }}
                                    br
                                    em 
                                        b Tel.: 
                                        span {{ data.nr_telefone_f }}
                                    br
                                    em
                                        b Sexo: 
                                        span {{ data.ie_sexo_f }}
                                    br
                                    em
                                        b Idade: 
                                        span {{ data.nr_idade }}
                            Column(headerStyle="width: 7%;" header="Saldo" field="nr_saldo_med_empresa_f")
                            Column(headerStyle="width: 7%;" header="Tipo" field="cd_titular")
                                template(#body="{data}")
                                    span {{ data.cd_titular? "Dependente" : "Titular" }}
                            Column(headerStyle="width: 8%;" bodyClass="ta-center" header="Dt. Inclusão" field="dt_inclusao_f")
                            Column(headerStyle="width: 8%;" bodyClass="ta-center" header="Dt. Exclusão" field="dt_exclusao_f")
                            Column(headerStyle='width: 10%;' bodyStyle='padding:0' field='ie_situacao' header='Situação')
                                template(#body='props')
                                    .cell(:style="{'background-color': options.situacoes.find(item => item.value === props.data.ie_situacao)?.color}")
                                        span {{ options.situacoes.find(item => (item.value === props.data.ie_situacao) && (props.data.ie_situacao !== null))?.text }} <br />
                            Column(headerStyle="width: 8%; text-align: center" header="Ações")
                                template(#body="{data}")
                                    .ta-center
                                        Button.p-button-raised.p-button-rounded.mr-1(
                                            v-tooltip.top="'Anexos'"
                                            type="button"
                                            icon="jam jam-attachment"
                                            @click='dialogAnexos = true; dialogAnexos_data = formatAnexos(data)'
                                        )
                                        Button.p-button-raised.p-button-rounded.mr-1(
                                            v-tooltip.top="'Visualizar'"
                                            type="button"
                                            icon="jam jam-user"
                                            @click='setUser(data)'
                                        )
                                        Button.p-button-raised.p-button-rounded.p-button-danger(
                                            v-if='data.ie_situacao === "AT"'
                                            v-tooltip.top="'Solicitar Inativação'"
                                            icon="jam jam-minus-circle"
                                            @click="dialogApagar = true; dialogApagar_data = data"
                                        )

                        Paginator(:rows="paginator.per_page" :first="(paginator.page - 1) * paginator.per_page" :totalRecords="paginator.count" @page="onPage")

</template>

<style lang="scss">
.empresa-visualizar {
    .dialogApagar {
        width: 600px;
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .buscar-paciente{
        &.naoVer {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 24px 0;
            text-align: center;
        }
    }
    .small-text {
        font-size: 12px;
    }
    .p-input-icon-left,
    .p-input-icon-right {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .p-input-icon-left > i,
    .p-input-icon-right > i {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        cursor: pointer;
    }
    .p-input-icon-left > i:first-of-type {
        left: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-right > i:last-of-type {
        right: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-left > .p-inputtext {
        padding-left: 2rem;
    }
    .p-input-icon-right > .p-inputtext {
        padding-right: 2rem;
    }
}
</style>

<script>
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import ProgressBar from "primevue/progressbar";
import Tooltip from "primevue/tooltip";
import MostrarPaciente from "./../EmpresaMedEmpresa/MostrarPaciente"
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import BuscarPaciente from './../Paciente/BuscarPaciente'
import CustomDataTable from "../CustomComponents/CustomDataTable.vue";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import DialogAnexos from "./DialogAnexos.vue";

import moment from "moment";
import { Empresa, Paciente, DominioValor } from "./../../middleware";
import wsConfigs from "./../../middleware/configs";
import { fixTelefoneFormat } from "@/utils";
import { required } from 'vuelidate/lib/validators';

const _ = require('lodash');

export default {
    components: { Button, Column, DataTable, InputMask, InputText, Dialog, ProgressSpinner, Dropdown,
        Paginator, Panel, ProgressBar, Tooltip, MostrarPaciente, BuscarPaciente, Textarea, DialogAnexos,
        CustomDataTable },
    directives: { tooltip: Tooltip },
    watch: {
        "$refs.buscarPaciente": function(value){
            this.cliente = value.model;
        },
        "dialogApagar": function(val) {
            if(!val) {
                this.model.ds_motivo_dialog = "";
                this.model.ie_motivo_dialog = null;
                this.model.nm_comprovante_dialog = ""
                this.model.aq_anexo_dialog = null
            }
        },
    },
    created () {
        let id = null
        this.getDominio()
        Empresa.getDados().then( response => {
            if (response.status === 200) {
                id = response.data.id
                if (!isNaN(id)) this.getEmpresa(id)
            }
        })
    },
    data () {
        return {
            model: {
                nm_razao_social: null,
                nm_fantasia: null,
                nr_cnpj: null,
                nm_responsavel: null,
                nm_bairro: null,
                nm_rua: null,
                ds_complemento: null,
                nr_cep: null,
                nr_empresa: null,
                nr_telefone: null,
                nr_whatsapp: null,
                ds_email: null,
                nm_usuario_cri: null,
                nm_usuario_edi: null,
                aq_anexo: null,
                nm_comprovante: '',
                ds_motivo_dialog: '',
                ie_motivo_dialog: null,
                aq_anexo_dialog: null,
                nm_comprovante_dialog: '',
            },
            dialogAnexos: false,
            dialogAnexos_data: null,
            dialogApagar: false,
            dialogApagar_data: null,
            waitingApagar: false,
            dialogUser: {
                visible: false,
                data: null
            },
            tipo_correntista: [
                { value: "T", label: "Titular"},
                { value: "F", label: "Filho"}
            ],
            modelUser: {},
            filters: {
                nm_pessoa_fisica: '',
                nr_cpf: '',
                ie_situacao: null,
                cd_faixas: null,
            },
            isAdd: false,
            cliente: null,
            waiting: true,
            waitingAssociados: false,
            waitingPaciente: false,
            selectedPai: null,
            waitingAddC: false,
            list: [],
            clienteAlterado: false,
            clienteSelecionado:false,
            remocao: false,
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            options: {
                situacoes: [],
                ie_motivo: [],
                faixas: [],
            },
            params: {},
            totalizadores: [],
            tableData: [
                {
                    header: 'Total Aportes', 
                    field: 'total_apurado', 
                    hStyle: {
                        width:'20%'
                    },
                    bStyle: {
                        textAlign: 'center'
                    }
                },
                {
                    header: 'Total Rendimentos', 
                    field: 'total_rendido', 
                    hStyle: {
                        width:'20%'
                    },
                    bStyle: {
                        textAlign: 'center'
                    }
                },
                {
                    header: 'Total Utilizado', 
                    field: 'total_usado', 
                    hStyle: {
                        width:'20%'
                    },
                    bStyle: {
                        textAlign: 'center'
                    }
                },
                {
                    header: 'Total Devolvido', 
                    field: 'total_devolvido', 
                    hStyle: {
                        width:'20%'
                    },
                    bStyle: {
                        textAlign: 'center'
                    }
                },
                {
                    header: 'Saldo Atual', 
                    field: 'saldo_atual', 
                    hStyle: {
                        width:'20%'
                    },
                    bStyle: {
                        textAlign: 'center'
                    }
                },
            ]
        }
    },
    validations () {
            let v = {
                model: {
                    ie_motivo_dialog: { required },
                    ds_motivo_dialog: { required },
                }
            }
            
            return v
        },
    methods: {
        formatAnexos(params) {
            let anx1 = params.aq_comprovantes.find(item => item.ie_tipo_status === "SOI")
            let anx2 = params.aq_comprovantes.find(item => item.ie_tipo_status === "SOE")
            return Object.assign({}, {
                aq_anexo: anx1 ? anx1.aq_comprovante : null,
                aq_anexo2: anx2 ? anx2.aq_comprovante : null,
                id: anx1 ? anx1.cd_inclusao : null,
                id2: anx2 ? anx2.cd_inclusao : null,
            })
        },
        getFaixas(){ 
            Empresa.buscarFaixa({cd_empresa: this.model.id, ie_vigente: true }).then((response) => {
                if(response.status === 200) {
                    this.options.faixas.push({ text: "- Selecione -", value: null })
                    response.data.forEach(item => {
                        this.options.faixas.push({ text: `De ${item.nr_idade_inicial} até ${item.nr_idade_final}`, value: item.id })
                    })
                }
            })
        },
        getDominio(){ 
            DominioValor.findAll({ds_mnemonico: ['SITUACAO_CORRENTISTA', 'MOTIVO_INATIVAR_CORRENTISTA']}).then((response) => {
                if(response.status === 200) {
                    this.options.situacoes.push({ text: "- Selecione -", value: null })
                    response.data['SITUACAO_CORRENTISTA'].valores.forEach(item => {
                        this.options.situacoes.push({ text: item.ds_valor, value: item.ie_valor, color: item.ds_cor })
                    })
                    response.data['MOTIVO_INATIVAR_CORRENTISTA'].valores.forEach(item => {
                        this.options.ie_motivo.push({ text: item.ds_valor, value: item.ie_valor })
                    })
                }
            })
        },
        nomeComprovanteDialog() {
            const files = this.$refs.refComprovanteDialog.files;
            const comprovante = files.length && files[0];

            this.model.aq_anexo_dialog = comprovante;
            this.model.nm_comprovante_dialog = comprovante.name
        },
        nomeComprovante() {
            const files = this.$refs.refComprovante.files;
            const comprovante = files.length && files[0];

            this.model.aq_anexo = comprovante;
            this.model.nm_comprovante = comprovante.name
        },
        formatPrice (val) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
        },
        onPage (ev) {
            this.applyFilters(ev.page + 1);
        },
        applyFilters(page) {
            if (!this.model.id) return;

            this.paginator.page = page || 1;
            let params = {
                cd_empresa: this.model.id,
                paginacao: true,
                page: this.paginator.page,
                per_page: this.paginator.per_page,
                ie_associado_med_empresa: true
            };
            Object.keys(this.filters).forEach(key => {
                if (this.filters[key]) {
                    if (key === 'nr_cpf') params[key] = this.filters[key].replace(/(\.|-|_)/g, '');
                    else params[key] = this.filters[key];
                }
            });
            // Fake loading quando os filtros forem os mesmos
            if (_.isEqual(this.params, params)) {
                if (!this.waitingAssociados) {
                    this.waitingAssociados = true;
                    setTimeout(() => this.waitingAssociados = false, 300);
                }
            } else {
                this.params = Object.assign({}, params);
                this.getList(params);
            }
        },
        getPaciente(cd_pessoa_fisica) {
            this.waitingPaciente = true;
            Paciente.find(cd_pessoa_fisica, { cd_empresa: this.model.id, ie_associado_med_empresa: true }).then(response => {
                if (([200, 201]).includes(response.status)) {
                    this.waitingPaciente = false;
                    this.modelUser = response.data
                    if(this.modelUser.cd_cidade){
                        this.modelUser.nm_estado = this.modelUser.cd_cidade.nm_estado,
                        this.modelUser.nm_cidade = this.modelUser.cd_cidade.nm_cidade
                    }
                    if (this.modelUser.nr_cpf) {
                        this.modelUser.nr_cpf_f = this.modelUser.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                        this.modelUser.nr_cpf = this.modelUser.nr_cpf_f
                    }
                    this.modelUser.dt_nascimento_f = moment(this.modelUser.dt_nascimento).format('DD/MM/YYYY')
                    this.modelUser.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[this.modelUser.ie_sexo]
                    this.modelUser.label = this.modelUser.nr_cpf ? `${ this.modelUser.nm_pessoa_fisica } (CPF: ${ this.modelUser.nr_cpf_f })` :
                        `${ this.modelUser.nm_pessoa_fisica } (Data nasc.: ${ this.modelUser.dt_nascimento_f })`
                    if (this.onSelectPessoaFisica) this.onSelectPessoaFisica()
                }
                else{
                    this.$toast.error("Paciente não encontrado")
                }
            })
        },
        getList(params){
            this.totalizadores = []
            this.waitingAssociados = true
            Empresa.listarAssociados(params).then( response => {
                this.waitingAssociados = false
                if (response.status === 200) {
                    response.data.results.forEach(paciente => {
                        paciente.nm_pessoa_fisica = paciente.cd_titular ? `${paciente.nm_pessoa_fisica} (dependente)` : paciente.nm_pessoa_fisica
                        paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
                        paciente.dt_inclusao_f = moment(paciente.dt_inclusao).format('DD/MM/YYYY')
                        if (paciente.dt_exclusao) paciente.dt_exclusao_f = moment(paciente.dt_exclusao).format('DD/MM/YYYY')
                        paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
                        if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)
                        if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                        paciente.nr_saldo_med_empresa_f = this.formatPrice(paciente.nr_saldo_med_empresa)
                    })
                    this.list = response.data.results
                    this.totalizadores.push(
                        _.omit(response.data, ['count', 'results'])
                    )
                    this.paginator.count = response.data.count
                }
            })
        },
        getEmpresa(id) {
            this.waiting = true;
            Empresa.find(id).then(response => {
                if (response.status === 200) {
                    let keys = Object.keys(this.model);
                    keys.forEach(key => this.model[key] = response.data[key]);
                    this.model.id = response.data.id;
                    this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm');
                    this.model.nr_cnpj_f = this.model.nr_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
                    this.model.nr_cep_f = this.model.nr_cep.replace(/(\d{5})(\d{3})/, '$1-$2');
                    this.model.nr_telefone_f = fixTelefoneFormat(this.model.nr_telefone);
                    if (response.data.dt_atualizado) {
                        this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm');
                    }
                    this.applyFilters();
                    this.getFaixas()
                }
                this.waiting = false;
            })
        },
        setUser(data){
            this.getPaciente(data.id);
            this.selectedPai = data.cd_titular
            this.dialogUser.visible = true;
            this.dialogUser.data = data;
        },
        remove() {
            this.remocao = true
            
            this.$v.$touch()
            if (this.$v.$invalid) return 0
            let dataSend ={
                cd_associado: this.dialogApagar_data.cd_empresa_associado,
                ie_motivo: this.model.ie_motivo_dialog,
                ds_observacao: this.model.ds_motivo_dialog,
                aq_anexo: this.model.aq_anexo_dialog,
            }
            this.waitingApagar = true;
            Empresa.solicitarExclusaoCorrentista(dataSend).then(response => {
                this.waitingApagar = false;
                if(([200,201]).includes(response.status)){
                    this.$toast.success("Solicitação feita com sucesso");
                    location.reload()
                }else if([400, 401, 404].includes(response.status)){
                    if (response.data.detail) {
                        if (typeof response.data.detail == 'string') {
                            this.$toast.error(response.data.detail, { duration: 3000 });
                        } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                    } else if (response.data){
                        Object.values(response.data).forEach(error => {
                            error.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        })
                    }
                }
            })
            this.model.ie_motivo_dialog = null
            this.model.ds_motivo_dialog = ""
            this.model.nm_comprovante_dialog = ""
            this.model.aq_anexo_dialog = null
            this.remocao = false
        },
        handleSubmit(){
            let dataSend = {
                cd_empresa: this.model.id,
                cd_pessoa_fisica: this.$refs.buscarPaciente.model.id,
                aq_anexo: this.model.aq_anexo,
            }
            this.waitingAddC = true;
            Empresa.solicitarInclusaoCorrentista(dataSend).then(response => {
                this.waitingAddC = false;
                if(([200,201,204]).includes(response.status)){
                    this.isAdd = false; 
                    this.clienteAlterado = false;
                    this.$toast.success("Solicitação feita com sucesso");
                    location.reload()
                } else if (response.status === 400) {
                    if (response.data.detail) {
                        if (typeof response.data.detail == 'string') {
                            this.$toast.error(response.data.detail, { duration: 3000 });
                        } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                    } else if (response.data.non_field_errors) {
                        response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                    }
                }
            })
        }
    }
}
</script>
    